import { PageTypes, Theme, ThemeContext } from "assets";
import { graphql } from "gatsby";
import { convertHero } from "molecules";
import {
  convertComponentList,
  PageLayout,
  StoriesLandingPageContent,
} from "organisms";
import PropTypes from "prop-types";
import React from "react";

const StoriesLandingPage = ({ data }) => {
  const storyData = data?.craftAPI?.entry;
  const storyTagData = data?.craftAPI?.categories;

  const children = convertComponentList(storyData?.componentList);

  // Place the hero at the top of the page
  children.unshift(convertHero(storyData));

  return (
    <ThemeContext.Provider
      value={{
        theme: Theme.Black,
        fontType: "",
      }}
    >
      <PageLayout
        coverImage={storyData.coverImage}
        pageType={PageTypes.STORY}
        shortDescription={storyData.shortDescription}
        theme={Theme.Black}
        title={storyData.title}
      >
        {children}
        <StoriesLandingPageContent
          storyData={storyData}
          storyTagData={storyTagData}
        />
      </PageLayout>
    </ThemeContext.Provider>
  );
};

StoriesLandingPage.propTypes = {
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      categories: PropTypes.array,
      entry: PropTypes.shape({}),
    }),
  }),
};

export const storiesQuery = graphql`
  query ($uri: [String]) {
    craftAPI {
      categories(group: ["timePeriods", "topic"]) {
        id
        title
        groupHandle
        ... on CraftAPI_timePeriods_Category {
          displayTitle
          date
        }
      }
      entry(uri: $uri) {
        ... on CraftAPI_story_storiesLanding_Entry {
          id
          coverImageCropStyle
          coverImagePositionOverride
          coverImage {
            ...ImageMetadataFragment
          }
          longDescription
          shortDescription
          heroType
          title
          featuredStory {
            ... on CraftAPI_story_story_Entry {
              id
              coverImageCropStyle
              coverImagePositionOverride
              coverImage {
                ...ImageMetadataFragment
              }
              shortDescription
              storyType(label: true)
              topicTag {
                title
              }
              title
              uri
            }
            ... on CraftAPI_story_ptpTopic_Entry {
              id
              storyType(label: true)
              topicTag {
                title
              }
              title
              coverImage {
                ...ImageMetadataFragment
              }
              shortDescription
              children(limit: 1) {
                ... on CraftAPI_story_ptpEntry_Entry {
                  id
                  title
                  shortDescription
                  coverImage {
                    ...ImageMetadataFragment
                  }
                  uri
                }
              }
            }
          }
        }
        children {
          ... on CraftAPI_story_story_Entry {
            id
            coverImageCropStyle
            coverImagePositionOverride
            coverImage {
              ...ImageMetadataFragment
            }
            shortDescription
            storyType(label: true)
            topicTag {
              title
            }
            timePeriod(limit: 1) {
              title
            }
            title
            uri
          }
          ... on CraftAPI_story_ptpTopic_Entry {
            id
            storyType(label: true)
            topicTag {
              title
            }
            title
            coverImage {
              ...ImageMetadataFragment
            }
            shortDescription
            children(limit: 1) {
              ... on CraftAPI_story_ptpEntry_Entry {
                id
                title
                shortDescription
                coverImage {
                  ...ImageMetadataFragment
                }
                uri
              }
            }
          }
        }
      }
    }
  }
`;

export default StoriesLandingPage;
